import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { Header, Benefit } from 'sections/solution';
import { useKisSolutionSharePageData } from 'graphqlHooks';
import { Spacer } from 'components';
import { Cta } from 'components/section';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';
import { useSolutionPageData } from 'sections/solution';
import {
  BenefitSectionSpacer,
  FlexseedBreadCard,
  GetPaidCard,
  HeaderSectionSpacer,
  ProfileCard,
  TimelineCard
} from 'sections/solution/benefit/components';
import { ChatsCard } from 'sections/solution/benefit/components/ChatsCard';
import { Grass, PointerTwo, ShockOutlined, SignalBar } from 'components/svgs';

// Data

const SolutionSell = () => {
  const { seo } = useSolutionPageData();
  const {
    header: cmsHeader,
    benefits: { solution_benefits }
  } = useKisSolutionSharePageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header data={cmsHeader} ImageDecoration={ImageDecoration} />
      <HeaderSectionSpacer />
      <Box as="main">
        <Benefit data={solution_benefits[0].data} isFirstBenefit>
          <TimelineCard />
        </Benefit>
        <DecoratedSpacerOne />
        <Benefit data={solution_benefits[1].data} isRightSideTextBlock>
          <ChatsCard />
        </Benefit>
        <DecoratedSpacerTwo />
        <Benefit data={solution_benefits[2].data}>
          <ProfileCard />
        </Benefit>
        <Spacer
          height={['23.3rem', null, '28.5rem', null, '41.3rem', '33.9rem']}
        />
        <Cta />
      </Box>
      <Spacer height={['8rem', null, '13.5rem', null, '6.3rem', '9.7rem']} />
    </>
  );
};

export default injectIntl(SolutionSell);

const ImageDecoration = () => {
  return (
    <>
      <ShockOutlined
        sx={{
          position: 'absolute',
          width: '2.4rem',
          top: ['1.2rem', null, '3.9rem', null, '5.9rem', '7.9rem'],
          left: ['4.1rem', null, '5.8rem', null, '7.2rem', '8rem']
        }}
      />
      <PointerTwo
        sx={{
          position: 'absolute',
          width: ['2.3rem', null, '2.3rem', null, '2.3rem', '3.1rem'],
          top: ['4.6rem', null, '8.5rem', null, '11.3rem', '12rem'],
          right: ['6.8rem', null, '9.7rem', null, '11.7rem', '13.6rem']
        }}
      />
    </>
  );
};

const DecoratedSpacerOne = () => {
  return (
    <BenefitSectionSpacer>
      <SignalBar
        sx={{
          position: 'absolute',
          width: ['6.3rem', null, '6.3rem', null, '7.5rem', '10.3rem']
        }}
      />
    </BenefitSectionSpacer>
  );
};
const DecoratedSpacerTwo = () => {
  return (
    <BenefitSectionSpacer>
      <Grass
        sx={{
          width: '5.5rem',
          position: 'absolute',
          transform: 'rotate(-30deg)'
        }}
      />
    </BenefitSectionSpacer>
  );
};
