import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { ChatCardItem } from 'components';

// data
import { useSolutionPageData } from 'sections/solution/useSolutionPageData';

import { CardBackgroundOne } from 'components';

export const ChatsCard = () => {
  const {
    cards: { chatsCard }
  } = useSolutionPageData();

  return (
    <Box
      sx={{
        mt: ['4.8rem', null, 0, null, 0, 0],
        gridColumn: ['1/13', null, '13/25', null, '3/ span 9', '4/ span 7'],
        gridRow: [null, null, 3, null, '1/ span 2', 1],
        position: 'relative'
      }}>
      <FirstChat data={chatsCard[0]} />
      <SecondChat data={chatsCard[1]} />
      <ThirdChat data={chatsCard[2]} />
      <CardBackground />
    </Box>
  );
};

const FirstChat = ({ data: { image, message } }) => {
  return (
    <ChatCardItem
      isSmall
      image={image}
      message={message}
      sx={{
        width: ['29.2rem', null, '31.8rem', null, '32.8rem', '30.8rem'],
        ml: ['auto', null, 0, null, 0, 0]
      }}
    />
  );
};
const SecondChat = ({ data: { image, message } }) => {
  return (
    <ChatCardItem
      image={image}
      isSmall
      message={message}
      sx={{
        width: ['29.1rem', null, '31.8rem', null, '28.6rem', '25.4rem'],
        mt: ['2.4rem', null, '1.6rem', '2.4rem', '2.4rem'],
        ml: [null, null, 'auto', null, 'auto', 'auto']
      }}
    />
  );
};
const ThirdChat = ({ data: { image, message } }) => {
  return (
    <ChatCardItem
      image={image}
      isSmall
      message={message}
      sx={{
        width: ['29.2rem', null, '31.8rem', null, '28.6rem', '25.4rem'],
        mt: ['2.4rem', null, '1.6rem', '2.4rem', '2.4rem'],
        ml: ['auto', null, 0, null, 0, 'auto'],
        mr: [null, null, null, null, null, 'auto']
      }}
    />
  );
};

const CardBackground = () => {
  return (
    <CardBackgroundOne
      preserveAspectRatio="none"
      sx={{
        position: 'absolute',
        width: ['36.1rem', null, '36rem', null, '41rem', '47rem'],
        top: ['-2.6rem', null, '-2.7rem', null, '-2rem', '-4rem'],
        height: [null, null, null, null, '36rem', '41.6rem'],
        left: '50%',
        transform: 'translate(-50%, 0)',
        color: 'darkBackground',
        zIndex: -1
      }}
    />
  );
};
